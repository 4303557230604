import React from "react"
import ContactPage from "../components/ContactPage"
import Footer from "../components/Footer"
import Layout from "../components/layout"
import Seo from "../components/seo"
export default function Thanks() {
  return (
    <Layout>
      <Seo title="Thank you" />
      <div className="min-h-screen p-24">
        <h1>Thank you for your message!</h1>
      </div>
      <Footer />
    </Layout>
  )
}
