import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function Contact() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 40 }) {
        content
        id
        office {
          offices {
            description
            information
            officeName
          }
        }
      }
    }
  `)
  const offices = data.wpPage.office?.offices
  console.log(offices)
  const { content } = data.wpPage
  return (
    <div className="bg-white px-4 md:px-24 py-16 md:py-32  mx-auto">
      <div className="grid md:grid-cols-4  gap-4 md:gap-16">
        <div className="space-y-4 md:col-span-2">
          {" "}
          <div className="space-y-4 pb-4">
            <p className="">
              <a href="mailto:info@newburypharma.com">
                info&#64;newburypharma.com
              </a>
            </p>
            <p className=" flex-1">
              <a href="https://www.linkedin.com/company/newbury-pharmaceuticals">
                Join us on Linkedin
              </a>
            </p>
          </div>
          <div className="border-b border-gray-200 p"></div>
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="space-y-4"
          />
        </div>

        <div className="">
          {offices.map((office, i) => {
            return <Office key={i} office={office} />
          })}
        </div>
      </div>
    </div>
  )
}
function Office({ office }) {
  const { information, officeName, description } = office
  return (
    <div>
      <div className="font-bold">{officeName}</div>
      <div className="">{description}</div>

      <div
        dangerouslySetInnerHTML={{ __html: information }}
        className="space-y-4"
      />
    </div>
  )
}
